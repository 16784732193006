import { createSignal } from "solid-js";
import { BTIcon } from "../bootstrap/BTIcon";

export function CopyButton(props: {
    value: string;
}) {
    const [copied, setCopied] = createSignal(false);

    return <>
        <BTIcon
            icon={copied() ? "check-lg" : "clipboard"}
            class="copy-btn"
            onClick={() => {
                navigator.clipboard.writeText(props.value);
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            }} />
    </>
}