import Popover from "bootstrap/js/dist/popover";
import { onMount } from "solid-js";
import { JSX } from "solid-js/jsx-runtime";

export function BTPopover(props: {
    title: string;
    content: string;
    placement: "top" | "right" | "bottom" | "left";
    children: JSX.Element;
    renderHTML?: boolean;
}) {
    let a: HTMLAnchorElement;

    onMount(() => new Popover(a, {
        html: props.renderHTML ?? false,
        // dismiss on next click
        trigger: "focus"
    }));

    return <a
        data-bs-toggle="popover"
        data-bs-title={props.title}
        data-bs-content={props.content}
        data-bs-placement={props.placement}
        tabIndex={0}
        ref={a}
    >
        {props.children}
    </a>
}