import { QRCodeSVG } from "@akamfoad/qrcode";
import { Match, Switch } from "solid-js";
import { syncState } from "..";
import { CONFIG } from "../../config";
import { BTIcon } from "./bootstrap/BTIcon";
import { BTPopover } from "./bootstrap/BTPopover";
import { CopyButton } from "./util/CopyButton";

export function SyncStateDisplay() {
    const viewLink = `${location.origin}/view.html`;
    const qrSVG = new QRCodeSVG(viewLink).toDataUrl();
    const qrImage = `<img src="${qrSVG}" width=${CONFIG.qrCodeSize} height=${CONFIG.qrCodeSize}/>`;

    return <>
        <span class="ms-2">
            <Switch>
                <Match when={syncState() === "loading"}>
                    <div class="spinner-border mx-2" id="sync-loading-spinner"></div>
                    <span class="ws-status">Verbinden...</span>
                </Match>
                <Match when={syncState() === "open"}>
                    <BTIcon icon="check2" size={18} class="text-success" />
                    <span class="ws-status me-1">Sync aktiv</span>
                    <BTPopover
                        title="Sync"
                        content="Der aktuelle Spielstand ist für alle unter <a href='/view.html' target='_blank'>/view.html</a> einsichtbar. Alle Änderungen werden live übernommen."
                        placement="bottom"
                        renderHTML
                    >
                        <BTIcon icon="info-circle-fill" class="info-btn" />
                    </BTPopover>
                    <span class="ms-3">
                        Link zum Teilen:&nbsp;
                        <a href={viewLink} target="_blank" class="me-1">{viewLink}</a>
                        <CopyButton value={viewLink} />
                        <BTPopover
                            title="QR-Code"
                            content={qrImage}
                            placement="bottom"
                            renderHTML
                        >
                            <BTIcon icon="qr-code" class="copy-btn" />
                        </BTPopover>
                    </span>
                </Match>
                <Match when={syncState() === "unavailable"}>
                    <BTIcon icon="x-lg" class="text-danger" />
                    <span class="ws-status me-1">Sync deaktiviert</span>
                    <BTPopover title="Sync" content="Entweder ist der Server nicht erreichbar oder ein anderer Fehler ist aufgetreten. Der aktuelle Spielstand wird im Browser gespeichert. Lade die Seite neu, um es nochmal zu versuchen" placement="right" renderHTML>
                        <BTIcon icon="info-circle-fill" class="info-btn" />
                    </BTPopover>
                    <span class="ms-3">
                        Wolltest du nur <a href="/view.html">die Ergebnisse</a> anschauen?
                    </span>
                </Match>
                <Match when={syncState() === "unauthorized"}>
                    <BTIcon icon="x-lg" class="text-danger" />
                    <span class="ws-status me-1">Sync deaktiviert</span>
                    <BTPopover title="Sync" content="Du hast aktuell keine Berechtigung, um den Spielstand zu synchronisieren. Der aktuelle Spielstand wird trotzdem im Browser gespeichert" placement="right" renderHTML>
                        <BTIcon icon="info-circle-fill" class="info-btn" />
                    </BTPopover>
                    <span class="ms-3">
                        Wolltest du nur <a href="/view.html">die Ergebnisse</a> anschauen?
                    </span>
                </Match>
            </Switch>
        </span>
    </>;
}
