import { produce } from "solid-js/store";
import { Player } from "../../types";
import { getCurrGame } from "../gameUtil";
import { usePlayerData } from "./PlayerDataContext";
import { BTIcon } from "./bootstrap/BTIcon";
import { BTTooltip } from "./bootstrap/BTTooltip";

export function ApplyCalledButton() {
    const [players, changePlayerData] = usePlayerData();
    const currGame = () => getCurrGame(players);

    return <>
        <BTTooltip text="Angesagte Stiche für alle übernehmen" placement="top">
            <button
                class="btn btn-primary"
                onclick={() => {
                    const game = currGame() - 1;

                    changePlayerData(produce(players => {
                        // @ts-ignore
                        players.forEach((p: Player) => {
                            const gd = p.gameData[game];

                            if (gd.numMade === null)
                                // only replace numbers that aren't entered yet
                                p.gameData[game].numMade = p.gameData[game].numCalled;
                        });
                    }));
                }}
            >
                <BTIcon icon={"box-arrow-in-left"} class="me-0" />
            </button>
        </BTTooltip>
    </>;
}
