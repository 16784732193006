import { Show, createSignal, onMount } from "solid-js";
import { getCurrGame, tricksMismatch } from "../gameUtil";
import { ApplyCalledButton } from "./ApplyCalledButton";
import { usePlayerData } from "./PlayerDataContext";
import { Repeat } from "./util/Repeat";
import { NothingCalledButton } from "./NothingCalledButton";

const [cellHeights, setCellHeights] = createSignal({ cell: 0, topHeader: 0, secondHeader: 0 });

export function InfoTable() {
    onMount(() => {
        const firstHeaderCellHeight = document.querySelector(".player-table thead th")?.clientHeight ?? 0;
        // height of the second row of headers
        const secondHeaderCellHeight = document.querySelector(".player-table thead.second-header th")?.clientHeight ?? 0;
        const cellHeight = document.querySelector(".player-table tbody td")?.clientHeight ?? 0;

        setCellHeights({
            cell: cellHeight + 1,
            topHeader: firstHeaderCellHeight + 1,
            secondHeader: secondHeaderCellHeight + 1
        });
    });

    const [players,] = usePlayerData();
    const currGame = () => getCurrGame(players);

    return <>
        <table class="table player-table mismatch-table mt-3">
            <tbody>
                {/* empty rows to match with .player-table */}
                <tr><td style={{ height: `${cellHeights().topHeader}px` }}></td></tr>
                <tr><td style={{ height: `${cellHeights().secondHeader}px` }}></td></tr>
                <Repeat times={12}>{idx =>
                    <tr>
                        <td style={{ height: `${cellHeights().cell}px` }} class="align-middle">
                            <Show when={tricksMismatch[12 - idx - 1] && currGame() !== 12 - idx}>
                                {Math.abs(tricksMismatch[12 - idx - 1])}
                                {Math.abs(tricksMismatch[12 - idx - 1]) === 1 ? " Stich" : " Stiche"}
                                {tricksMismatch[12 - idx - 1] > 0 ? " zu viel" : " zu wenig"}
                            </Show>
                            <Show when={currGame() === 12 - idx}>
                                <ApplyCalledButton />
                                <Show when={currGame() === 1}>
                                    <NothingCalledButton />
                                </Show>
                            </Show>
                        </td>
                    </tr>}
                </Repeat>
            </tbody>
        </table >
    </>
}